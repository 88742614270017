<template>
  <v-card>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="rounded-xl" style="box-shadow: 0 0 60px -20px" max-width="1000">
          <v-row>
            <v-col cols="12" md="4" class="no-padding d-flex">
              <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat rounded="0">
                <v-card-title>
                  <v-img
                    :src="require('@/assets/images/logos/anavel_logo.png')"
                    max-height="150px"
                    max-width="120px"
                    alt="logo"
                    contain
                  ></v-img>
                </v-card-title>
                <v-card-title class="text-center justify-center muller-capitalized"
                  >Mes Services Assurance</v-card-title
                >
                <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                <v-card-text class="no-padding">
                  <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                </v-card-text>
                <!-- <v-footer color="EoleBlueLighten" class="text-center justify-center black--text">
                  <small class="caption EoleBlue--text"
                    >{{ appName }} {{ version }} &copy; {{ new Date().getFullYear() }}</small
                  >
                </v-footer> -->
              </v-card>
            </v-col>
            <v-col cols="12" md="8" class="pl-0 pt-0 pb-0 flex-fill flex d-flex flex-column">
              <v-card class="rounded-r-xl flex-fill flex d-flex flex-column ml-n4" flat rounded="0">
                <v-row class="mx-2 mt-5 h-full" align-content="center">
                  <v-col cols="12" md="12" class="h-full">
                    <v-card-text class="h-full">
                      <v-card outlined style="border-color: #ff4c51; color: #ff4c51 !important" class="h-full">
                        <v-card-text>
                          <v-row align-content="center" align="center" class="my-auto">
                            <v-col>
                              <v-card-title class="text-center justify-center EoleErrorBase--text">
                                <h1 class="muller-capitalized EoleErrorBase--text" style="color: #ff4c51 !important">
                                  Session expirée
                                </h1>
                              </v-card-title>
                              <v-card-text class="text-center">
                                <v-avatar color="EoleErrorBase" class="mr-2" size="64">
                                  <i class="fa-solid fa-user-slash fa-2x white--text" style="font-size: 1.5rem"></i>
                                </v-avatar>
                              </v-card-text>
                              <v-card-text style="color: #ff4c51 !important; font-size: 0.9rem" class="text-center">
                                Votre session a été fermée pour des raisons de sécurité.
                                <br />
                                Veuillez vous reconnecter pour continuer à utiliser Mes Services Assurance.</v-card-text
                              >
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card-actions class="text-center mb-5 mx-auto">
                  <v-btn color="EoleErrorBase" class="white--text" href="/auth/login" rounded text x-large outlined
                    ><i class="fa-solid fa-arrow-right-to-bracket mr-2"></i>connexion</v-btn
                  ></v-card-actions
                >
                <!-- <v-row class="mx-2 text-center" align-content="center">
                  <v-col cols="12" md="12">
                    <v-alert icon="mdi-account-off" type="error" prominent dense outlined
                      >Désolé, votre session a été fermée pour des raisons de sécurité après 4 heures d'inactivité.
                      <br />
                      Veuillez vous reconnecter pour continuer à utiliser Mes Services Assurance.
                    </v-alert>
                  </v-col>
                </v-row> -->
              </v-card>
            </v-col>
          </v-row>
          <!-- <v-card-actions class="d-flex justify-center">
          <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2">Nouveau sur notre plateforme ?</span>
            <router-link :to="{ name: 'pages-register-office' }">Créer un compte</router-link>
          </v-card-text>
        </v-card-actions> -->
        </v-card>
      </div>
    </div>
  </v-card>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css" // Load icons

export default {
  data: () => ({}),
  methods: {
    reloadPage() {
      if (navigator.onLine) {
        this.$router.push("/dashboard").catch(() => {})
      } else {
        window.location.reload()
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
